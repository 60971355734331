import * as React from "react";
import PageBlock from "../../components/Layout/PageBlock";
import PageLayout from "../../components/Layout/PageLayout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

const PAGE_TITLE = "Careers Quiz";

const CareersQuizPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
            </PageBlock>
        </PageLayout>
    );
};

export default CareersQuizPage;
